import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 328px);
        align-items: center;
        justify-content: center;
      `}
    >
      <h1>NOT FOUND</h1>
      <p>How did you end up here?!.</p>
      <Link to="/">&larr; Go back to the homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
